class websocketUtil {
    constructor(url, time,store) {
        this.stores = store
        this.is_open_socket = false //避免重复连接
        this.url = url //地址
        this.data = null
        //心跳检测
        this.timeout= time //多少秒执行检测
        this.heartbeatInterval= null //检测服务器端是否还活着
        this.reconnectTimeOut= null //重连之后多久再次重连
        try {
            return this.connectSocketInit()
        } catch (e) {
            console.log('catch');
            this.is_open_socket = false
            this.reconnect();
        }
    }

    // 进入这个页面的时候创建websocket连接【整个页面随时使用】
    async connectSocketInit() {
        let basedata = await this.getBaseData()
        // console.log("socket缓存信息：",basedata)
        this.socketTask = uni.connectSocket({
            url: this.url+`?access_token=${basedata["access_token"]}&website='wapservice'`,
            success:(res)=>{
                // console.log("正准备建立websocket中...",res);
                // 返回实例
                return this.socketTask
            },
        });
        this.socketTask.onOpen((res) => {
            clearTimeout(this.reconnectTimeOut)
            clearInterval(this.heartbeatInterval)
            this.is_open_socket = true;
            this.start();
            // 注：只有连接正常打开中 ，才能正常收到消息，后期有消息可以加入状态管理vuex
            this.socketTask.onMessage((res) => {
                let msg = JSON.parse(res.data);
                switch(msg.channel) {
                    case "normal": // 默认事件
                        break
                    case "reconnect": // 断开连接后重新连接socket
                        this.stops()
                        break;
                    case "bind_client_id": 
                        uni.setStorageSync('fd',msg.fd);
                        break;
                    default:
                        break;
                }
            });
        })
        // 监听连接失败，这里代码我注释掉的原因是因为如果服务器关闭后，和下面的onclose方法一起发起重连操作，这样会导致重复连接
        // uni.onSocketError((res) => {
        // 	console.log('WebSocket连接打开失败，请检查！');
        // 	this.is_open_socket = false;
        // 	this.reconnect();
        // });

        // 这里仅是事件监听【如果socket关闭了会执行】
        this.socketTask.onClose(() => {
            this.is_open_socket = false;
            this.reconnect();
        })
    }

    /**
     * 发送消息
     * @param channel 通道
     * @param msg 文本消息
     */
    send(channel = "heart",msg = "ping"){
        let basedata = this.getBaseData()
        basedata.channel = channel
        basedata.website = 'wapservice'
        basedata.message = msg
        // 注：只有连接正常打开中 ，才能正常成功发送消息
        this.socketTask.send({
            data: JSON.stringify(basedata),
            async success() {
            },
        });
        // console.log("发送消息：",msg)
    }
    //开启心跳检测
    start(){
        let basedata = this.getBaseData()
        this.heartbeatInterval = setInterval(()=>{
            let msg = {access_token:basedata['access_token'],platforms:basedata['platforms'],duankou:basedata['duankou'],appsource:basedata['appsource']}
            this.send("heart",msg);
        },this.timeout)
    }
    //重新连接
    reconnect(){
        //停止发送心跳
        clearInterval(this.heartbeatInterval)
        //如果不是人为关闭的话，进行重连
        if(!this.is_open_socket){
            this.reconnectTimeOut = setTimeout(()=>{
                this.connectSocketInit();
            },3000)
        }
    }
    // 主动关闭socket重新连接
    stops() {
        let _this = this
        uni.closeSocket({
            success:()=>{
                console.log("后台服务器命令关闭")
                setTimeout(()=>{
                    _this.reconnect()
                },1200  ``)

            }
        })
    }

    listenClose() {
        let _this = this
        uni.onSocketClose(function (res) {
            console.log('WebSocket 已关闭！');
            _this.reconnect()
        });
    }

    /**
     * 基础数据
     * @returns {{access_token, duankou: number, platforms: string}}
     */
    getBaseData() {
        let token = uni.getStorageSync('token');
        
		// console.log("这是socket的值:",appsource)
        return {access_token: token};
    }
}

export default websocketUtil
